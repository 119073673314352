import axios from 'axios'
import { createStore, createEffect } from 'effector'
import newsConfig from 'src/configs/news'

const getNewsList = createEffect(async (payload: Record<string, any> = {}) => {
  const { data } = await axios.post(newsConfig.getNewsList(), payload)

  return data
})

const news = createStore<any>({
  data: [],
  count: 0,
  pages: 0,
  loading: true
})
  .on(getNewsList, state => {
    return {
      ...state,
      loading: true
    }
  })
  .on(getNewsList.doneData, (state, payload: any) => {
    return {
      ...state,
      data: payload.data,
      count: payload.count,
      pages: payload.pages,
      loading: false
    }
  })

export { getNewsList }
export default { news }
