//** React Imports
import { useEffect, Fragment } from 'react'

// ** Next Imports
import dynamic from 'next/dynamic'

//** Effect Imports
import { useStore } from 'effector-react'

// ** React Imports
import { ReactNode } from 'react'

// ** Source code Imports
import { useSettings } from 'src/@core/hooks/useSettings'
import landingStore, { getTableForLanding, getLandingDetail } from 'src/state-manager/landing'
import runtimeStore from 'src/state-manager/runtime'
import Spinner from 'src/@core/components/spinner'
import DefaultLayout from 'src/layouts/default-layout'
import newsStore, { getNewsList } from 'src/state-manager/news'

const LandingPage = dynamic(() => import('src/views/pages/landing-page'), {
  ssr: false
})

const Home = () => {
  const { settings } = useSettings()

  const { preload: preloadVideo } = useStore(runtimeStore.video)
  const { artistsItems, landingDetails, workItItems, platforms, platformSlides, faqItems, controlsSlides } = useStore(
    landingStore.landing
  )
  const { data: newsData } = useStore(newsStore.news)

  useEffect(() => {
    getTableForLanding({
      key: 'artistsItems',
      tableName: 'landing_artists_slider'
    })

    getTableForLanding({
      key: 'workItItems',
      tableName: 'landing_how_work_it'
    })

    getTableForLanding({
      key: 'platforms',
      tableName: 'landing_platforms'
    })

    getTableForLanding({
      key: 'platformSlides',
      tableName: 'landing_platform_slides'
    })

    getTableForLanding({
      key: 'faqItems',
      tableName: 'landing_faq'
    })

    getTableForLanding({
      key: 'controlsSlides',
      tableName: 'controls_slides'
    })

    getNewsList({
      query: { ['data.showToLanding']: true }
    })

    getLandingDetail()
  }, [])

  const isLoading =
    artistsItems.loading ||
    landingDetails.loading ||
    workItItems.loading ||
    platforms.loading ||
    platformSlides.loading ||
    faqItems.loading ||
    controlsSlides.loading ||
    newsData.loading ||
    preloadVideo

  return (
    <Fragment>
      {isLoading && <Spinner settings={settings} />}
      <DefaultLayout sx={{
        height: isLoading ? 0 : 'auto',
        visibility: isLoading ? 'hidden' : 'visible'
      }}>
        <LandingPage
          loading={isLoading}
          artists={artistsItems.data}
          workItItems={workItItems.data}
          platforms={platforms.data}
          platformSlides={platformSlides.data}
          landingDetails={landingDetails.data}
          faqItems={faqItems.data}
          controlsSlides={controlsSlides.data}
          newsData={newsData}
        />
      </DefaultLayout>
    </Fragment>
  )
}

Home.getLayout = (page: ReactNode) => page

export default Home
