import { createStore, createEvent } from 'effector'

const onLoadedVideo = createEvent()

const video = createStore<any>({
  preload: true
})
  .on(onLoadedVideo, (state: any) => {
    return {
      ...state,
      preload: false
    }
  })

export { onLoadedVideo }

export default { video }
